import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./App.css";
import VideoBg from "../src/assets/video.mp4";

function App() {
  // Teslim tarihini belirliyoruz
  const deliveryDate = new Date("2025-02-28T23:59:59Z");

  // Mailto işlevi
  const handleContactClick = () => {
    window.location.href = "mailto:info@daonline.com";
  };

  return (
    <section className="container">
      <video src={VideoBg} autoPlay loop muted />
      <div className="clock_content">
        <h1> Your next favorite platform is on its way.</h1>{" "}
        <h3> "Discover the Future of Shopping Today!"</h3>{" "}
        <FlipClockCountdown
          className="flip-clock"
          to={deliveryDate}
          labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
          duration={0.5}
        />{" "}
        <button onClick={handleContactClick} className="button">
          Contact Me{" "}
        </button>{" "}
      </div>{" "}
    </section>
  );
}

export default App;
